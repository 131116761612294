<template>
    <div class="wrapper">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="bannerClose"
        />
        <nav-bar />
        <form class="courseForm" @submit="createCourse">
            <section class="courseForm__header">
                <h1 class="courseForm__title">
                    Create Tutorial
                </h1>
                <p class="courseForm__subtitle">
                    Fill the form below to create a tutorial
                </p>
            </section>
            <section class="courseForm__content courseForm__content--left">
                <div class="form__control">
                    <label class="form__label">Title</label>
                    <input v-model.trim="title"
                           class="form__input"
                           autofocus
                           placeholder="My awesome tutorial"
                    >
                </div>
                <div class="form__control">
                    <label class="form__label">Description</label>
                    <textarea v-model.trim="description"
                              class="form__textarea"
                              placeholder="My awesome tutorial descripiton"
                    />
                </div>
                <div class="form__control">
                    <label for="products" class="form__label">Products</label>
                    <div class="grid grid-cols-3 gap-2">
                        <check-box v-for="(product, index) in productList"
                                   :key="index"
                                   :name="product.name"
                                   :meta="product"
                                   :on-change="handleProducts"
                        />
                    </div>
                </div>

            </section>
            <section class="courseForm__content courseForm__content--right">
                <div class="form__control">
                    <label class="form__label">Artwork</label>
                    <div class="form__artwork">
                        <drop-zone :get-u-r-l="getImageURL"
                                   :get-upload-status="imageUploadStatus"
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label class="form__label">Tags</label>
                    <div class="tagInput">
                        <input v-model.trim="tag"
                               class="form__input"
                               placeholder="Example: sms"
                        >
                        <button class="button"
                                type="button"
                                @click="addTag"
                        >
                            <font-awesome-icon icon="plus" class="mr-2" />
                            Add
                        </button>
                    </div>
                </div>
                <div class="tags">
                    <div v-for="(tagName, index) in tags"
                         :key="index"
                         class="tag"
                    >
                        <button type="button"
                                class="tag__remove"
                                @click="removeTag(tagName)"
                        >
                            <font-awesome-icon icon="times" />
                        </button>
                        {{ tagName }}
                    </div>
                </div>
                <div class="form__control">
                    <label class="form__label">
                        Collaborators
                        <span v-if="searching"
                              class="text-gray-500 italic ml-2 flex items-center"
                        >
                            searching...
                        </span>
                    </label>
                    <search :search="searchCollaboratorsImpl">
                        <div slot="content" class="flex w-full flex-col">
                            <div v-for="(user, index) in filteredUsers"
                                 :key="index"
                                 class="h-12 px-4 flex
                                 items-center cursor-pointer hover:bg-gray-100"
                                 @mousedown.stop="addCollaborator(user)"
                            >
                                <span>
                                    <img v-if="user.profile"
                                         class="h-8 w-8 rounded-full overflow-hidden"
                                         :src="user.profile"
                                    >
                                    <div v-else class="user__name">
                                        {{ getAcronymn(user.name) }}
                                    </div>
                                </span>
                                <p class="text-gray-700 text-sm font-medium ml-2">
                                    {{ user.email }}
                                </p>
                            </div>
                        </div>
                    </search>
                </div>
                <div class="form__control">
                    <section class="w-full h-auto flex flex-col">
                        <user-list-item
                            v-for="(collaborator, index) in collaborators"
                            :key="index"
                            :collaborator="collaborator"
                            :remove="removeCollaborator"
                        />
                    </section>
                </div>
                <div class="form__control">
                    <label class="form__label">
                        Miscellaneous
                    </label>
                    <check-box name="Allow Egress" :isChecked="allowEgress" />
                </div>
            </section>
            <section class="courseForm__actions">
                <button class="button left"
                        type="button"
                        @click="goBack"
                >
                    <font-awesome-icon icon="arrow-left" class="mr-2" />
                    Back
                </button>
                <button class="button right" type="submit">
                    <font-awesome-icon icon="save" class="mr-2" />
                    Create
                </button>
            </section>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavBar from '@/components/ui/Navbar.vue';
import DropZone from '@/components/ui/Dropzone.vue';
import Banner from '@/components/ui/Banner.vue';
import Search from '@/components/ui/Select.vue';
import UserListItem from '@/components/ui/UserListItem.vue';
import debounce from 'underscore/cjs/debounce';
import CheckBox from '@/components/ui/CheckBox.vue';
import { bannerMixin } from '@/mixins/Banner';

export default {
    components: {
        NavBar,
        DropZone,
        CheckBox,
        UserListItem,
        Search,
        Banner,
    },

    mixins: [bannerMixin],

    data() {
        return {
            title: '',
            description: '',
            artwork: '',
            isImageUploading: false,
            productList: [
                { name: 'Airtime' },
                { name: 'SMS' },
                { name: 'Payments' },
                { name: 'Voice' },
                { name: 'USSD' }, { name: 'IOT' },
            ],
            products: [],
            tag: '',
            tags: [],
            collaborators: [],
            searching: false,
            allowEgress: false,
        };
    },

    computed: {
        ...mapState('users', ['users']),
        ...mapState('auth', ['user']),
        filteredUsers() {
            return this.users.filter(({ email }) => {
                if (this.user && this.user.email) {
                    return email !== this.user.email;
                }
                return email !== '';
            });
        },
    },

    methods: {
        ...mapActions('courses', {
            createCourseImpl: 'createCourse',
        }),
        ...mapActions('users', ['getUsers']),

        getAcronymn(name = '') {
            if (!name) return '';
            const nameParts = name.split(' ');
            if (nameParts.length > 1) {
                const firstName = nameParts[0];
                const lastName = nameParts[1];
                return `${firstName[0]}${lastName[0]}`;
            }
            if (nameParts.length === 1) {
                const firstName = nameParts[0];
                return firstName[0];
            }
            return '';
        },

        getImageURL(url) {
            this.artwork = url;
        },

        imageUploadStatus({ isUploading }) {
            this.isImageUploading = isUploading;
        },

        goBack(e) {
            e.preventDefault();
            this.$router.push({ path: '/home' });
        },

        addTag() {
            if (this.tag !== '') {
                this.tags.push(this.tag);
                this.tag = '';
            }
        },

        removeTag(tagName = '') {
            this.tags = this.tags.filter((tag) => tag !== tagName);
        },

        handleProducts(status, meta = {}) {
            if (status) {
                this.products = [meta.name, ...this.products];
                return;
            }
            this.products = this.products
                .filter((product) => product !== meta.name);
        },

        async searchCollaborators({ value = '' }) {
            try {
                this.searching = true;
                await this.getUsers({ email: value });
                this.searching = false;
            } catch (err) {
                this.searching = false;
                throw err;
            }
        },

        addCollaborator(user = {}) {
            const found = this.collaborators
                .find(({ email }) => email === user.email);
            if (!found) {
                this.collaborators = [user, ...this.collaborators];
            }
        },

        removeCollaborator(user = {}) {
            this.collaborators = this.collaborators
                .filter(({ email }) => email !== user.email);
        },

        // eslint-disable-next-line func-names
        searchCollaboratorsImpl: debounce(async function ({ value = '' }) {
            await this.searchCollaborators({ value });
        }, 500),

        async createCourse(e) {
            e.preventDefault();
            try {
                const courseData = {
                    title: this.title,
                    description: this.description,
                    artwork: this.artwork,
                    tags: this.tags.map((tag) => tag.toLowerCase()),
                    products: this.products.map((product) => product.toLowerCase()),
                    collaborators: this.collaborators,
                    allowEgress: this.allowEgress,
                };

                if (this.isImageUploading) return;
                if (!this.title) throw (new Error('Title required'));
                if (!this.tags.length > 0) throw (new Error('Tags required'));

                const course = await this.createCourseImpl(courseData);
                if (course) {
                    this.$router.push({
                        path: `/courses/${course.id}/lessons/dashboard`,
                    });
                }
            } catch (ex) {
                if (ex.status && ex.status === 400) {
                    this.renderBanner('error', 'data provided is incorrect');
                    return;
                }
                this.renderBanner(
                    'error', ex.message || 'Error creating tutorial',
                );
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply w-full;
    @apply flex;
    min-height: 100vh;
}

.courseForm{
    @apply relative;
    @apply h-auto;
    @apply mt-16;
    @apply mx-auto;
    width: 70%;
    @apply grid;
    @apply gap-6;
    @apply p-12;
    grid-template-columns: repeat(50%);
    grid-template-rows: 5rem 1fr 3rem;
    grid-template-areas:
        "header header"
        "contentLeft contentRight"
        "actions actions";
}
.courseForm__content{
    &--left{
        grid-area: contentLeft;
    }
    &--right{
        grid-area: contentRight;
    }
}
.courseForm__actions {
    grid-area: actions;
    @apply flex;
    @apply items-center;
    @apply justify-end;
}

.courseForm__title {
    @apply text-2xl;
    @apply font-semibold;
    @apply text-gray-900;
}

.courseForm__subtitle {
    @apply text-base;
    @apply font-medium;
    @apply text-gray-700;
}

.tagInput {
    @apply flex;
    @apply w-full;
}

.tags {
    @apply flex;
    @apply flex-wrap;
}

.tag {
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply bg-white;
    @apply grid;
    @apply rounded-full;
    @apply grid;
    @apply place-items-center;
    @apply text-sm;
    @apply text-gray-700;
    @apply mx-1;
    @apply my-1;
    @apply relative;
}

.tag__remove {
    @apply h-5;
    @apply w-5;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply absolute;
    @apply rounded-full;
    @apply bg-white;
    @apply flex;
    @apply justify-center;
    @apply text-gray-700;
    @apply items-center;
    @apply text-xs;
    @apply cursor-pointer;
    top: -.5rem;
    right: 0;
}

.button {
    @apply px-5;
    @apply py-2;
    @apply rounded-md;
    @apply shadow-sm;
    @apply h-12;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply bg-white;
    @apply text-gray-700;
    @apply text-base;
    @apply font-medium;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply mx-2;
    &.left {
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply bg-gray-100;
        &:hover,&:focus {
            @apply text-gray-900;
        }
    }
    &.right {
       @apply bg-accent;
       @apply text-white;
    }
}

.user__name {
    @apply font-medium;
    @apply text-sm;
    @apply text-white;
    @apply rounded-full;
    @apply bg-accent;
    @apply h-8;
    @apply w-8;
    @apply grid;
    @apply place-items-center;
}
</style>
